<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="highseas">
        <div class="box">
            <p class="box2_tit">
                <span @click="dianji_fanhui">客户管理</span>
                <i class="el-icon-arrow-right"></i>
                <span>公海池</span>
            </p>
            <div class="box2_title">
                <div class="box2_title_box">
                    <ul>
                        <li v-for="(i,index) in biaotou2_list" :key="index" @click="dianji_fenpei(i)">{{i}}</li>
                        <li v-if="zhuguan&&zhuguan_liyuan" @click="dianji_shanchu_kehu">删除</li>
                    </ul>
                    <div>
                        <!-- <div class="shenpi_xuanze_rqi">
                            <el-select v-model="gonghai_sj_zhi" clearable @change="shijian_xuanze" placeholder="请选择">
                                <el-option
                                v-for="item in gonghai_sj_list"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="min_rqi_qujian">
                            <el-date-picker
                                v-model="gonghai_chi_time"
                                type="daterange"
                                class="rqi_qujian_kuang"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                @change="rqi_xuanze"
                                >
                            </el-date-picker>
                        </div> -->
                        <i class="el-icon-search"></i>
                        <input type="text" v-model="sousuo_text" @keyup.enter="sousuo" placeholder="搜索">
                        <i @click="sousuo_text='',dangqian_yeshu=1,sousuo()" class="el-icon-circle-close"></i>
                    </div>
                </div>
            </div>
            <div class="xiaoshou_table">
                <el-table
                    ref="multipleTable"
                    :data="tableData2"
                    :cell-style="liebiao"
                    :header-cell-style="biaotou"
                    @row-click='hangdian'
                    tooltip-effect="dark"
                    style="width: 100%"
                    height="6.5rem"
                    @selection-change="xuanzhong">
                    <el-table-column
                        type="selection"
                        width="77">
                    </el-table-column>
                    <el-table-column v-for="(i,index) in biao2_tit" :key="index"
                        :prop="i.con"
                        :label="i.name"
                        >
                        <template slot-scope="props">
                            <span v-if="i.con!='sj_time'">{{props.row[i.con]}}</span>
                            <span v-if="props.row[i.con]==undefined||props.row[i.con].length==0">—</span>
                            <div v-if="i.con=='sj_time'" >
                                <p :class="props.row[i.con]==0?'green':(props.row[i.con]==1?'yellow':'red')"></p>
                                {{props.row.time}}
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-pagination
                @size-change="dianji_tiaoshu"
                @current-change="dianji_yeshu"
                :current-page="dangqian_yeshu"
                class="fenye_qi"
                layout="total,sizes, prev, pager, next, jumper"
                :page-sizes="[10, 20, 30, 40,50,60,70,80,90,100]"
                :page-size='tiaoshu'
                :total="dangqian_zongshu">
            </el-pagination>
            <!-- ,sizes -->
        </div>
        <div class="beijing" v-if="xianshi_shezhi" @click="xianshi_shezhi=false">
            <div class="beijing_box" @click.stop>
                <p class="beijing_box_tit">显示设置</p>
                <div class="beijing_box_con">
                    <div>
                        <p class="beijing_box_con_tit">选择：</p>
                        <p class="beijing_box_con_quanbu" @click="dianji_xuanze_quanbu"><img :src="xianshi_quanbu?require('../../assets/okblue.png'):require('../../assets/noccc.png')">全部</p>
                        <ul class="renyuan_xuanze_list">
                            <li class="renyuan_xuanze_list_li" v-for="(i,index) in zhanshi_list" :key="index" @click="xuanze_danxuan(i)">
                                <img class="xuanxiang_kuang" :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')">{{i.name}}
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p class="beijing_box_con_tit">显示项：</p>
                        <ul class="renyuan_xuanze_list">
                            <li class="renyuan_xuanze_list_li" v-for='(i,index) in xianshi_xiang_list' :key="index">
                                {{i.name}}<img class="shanchu_xianshi" @click="dianji_shanchu_xianshi(i,index)" src="../../assets/guanbi_zhu.png">
                            </li>
                        </ul>
                    </div>
                </div>
                <p class="beijing_box_foot">
                    <span @click="dianji_lie_queding">确定</span>
                    <span @click="xianshi_shezhi=false">取消</span>
                </p>
            </div>
        </div>
        <!-- <div class="beijing" v-if="zhipai_liebiao" @click="zhipai_liebiao=false">
            <div class="beijing_box" @click.stop>
                <p class="beijing_box_tit">人员选择</p>
                <div class="beijing_box_con">
                    <div>
                        <p class="beijing_box_con_tit">选择：</p>
                        <p v-if='renyuan_list.length==0' style="text-align:center;line-height:0.46rem;color:#ccc;">暂无数据</p>
                        <ul class="renyuan_xuanze_list">
                            <li class="renyuan_xuanze_list_li" v-for="(i,index) in renyuan_list" :key="index" @click="zhipai_danxuan(i)">
                                <img class="xuanxiang_kuang" :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')">{{i.staff_name}}——{{i.value_num!='-1'?i.dept_list[i.value_num].dept_name:''}}
                                <ul v-if="i.value_zhipai" @click.stop>
                                    <li v-for="(it,ind) in i.dept_list" @click="xuanze_zhipai_renyuan(i,ind)" :key="ind">
                                        <img class="xuanxiang_kuang" :src="i.value_num==ind?require('../../assets/red_quan.png'):require('../../assets/ccc_quan.png')">{{it.dept_name}}
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p class="beijing_box_con_tit">已选：</p>
                        <ul class="renyuan_xuanze_list">
                            <li class="renyuan_xuanze_list_li" v-for='(i,index) in yixuan_ren_list' :key="index">
                                {{i.name}}——{{i.dept_name}}<img class="shanchu_xianshi" @click="dianji_ren_xianshi(i,index)" src="../../assets/guanbi_zhu.png">
                            </li>
                        </ul>
                    </div>
                </div>
                <p class="beijing_box_foot">
                    <span @click="dianji_zhipai_queding">确定</span>
                    <span @click="zhipai_liebiao=false">取消</span>
                </p>
            </div>
        </div> -->
        <el-dialog title="选择人员" :center="true" :show-close='false' append-to-body :visible.sync="zhipai_liebiao">
            <div class="tiao_bumen2">
                <div class="xuanze_left">
                    <p class="tit">选择：</p>
                    <!-- <div class="xuanze_sousuo">
                        <i class="el-icon-search"></i>
                        <input type="text" placeholder="搜索" v-model="sousuo_text2" @keyup.enter='shaixuan_sousuo'>
                    </div> -->
                    <div class="mianbaoxie">
                        <div v-for="(a,idx) in mianbao_chaosong_list" :key='idx'  @click="mianbao_chaosong_dian(a,idx)">
                            <p :class="mianbao_chaosong_list.length-1==idx?'p1':'p2'">{{a.name}}</p>
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <!-- <div class="quanxuan" v-if="chaosong_qiye_renyuan_list.length!=0">
                        <img @click="dianji_chaosong_quanxuan" :src="renyuan_chaosong_quanxuan?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                        <span>全选</span>
                    </div> -->
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul>
                                <li v-for="(a,idx) in chaosong_qiye_bumen_list" :key="idx" @click="dianji_chaosong_bumen(a)">
                                    <!-- <img class="img2" @click.stop='chaosong_bumen(a)' :src="a.zhi?require('../assets/okblue.png'):require('../assets/noccc.png')" alt=""> -->
                                    <p style="margin-left:0.16rem;">{{a.dept_name}}</p>
                                </li>
                            </ul>
                            <ul>
                                <li v-for="(a,idx) in chaosong_qiye_renyuan_list" :key="idx" style='justify-content:left;margin-left:-0.07rem;'>
                                    <img class="img2" @click="dianji_chaosong_xuanren(a)" :src="a.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                                    <p>{{a.name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="xuanze_right">
                    <p class="tit">已选：</p>
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul>
                                <li v-for="(a,idx) in chaosong_renyuan_list_xuan" :key="idx">
                                    <p>{{a.name}}---{{a.dept_name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="foote">
                <p class="queding" @click="chaosong_renyuan_sure">确定</p>
                <p class="quxiao" @click="zhipai_liebiao=false;chaosong_renyuan_list_xuan=[]">取消</p>
            </div>
        </el-dialog>
        <div class="beijing" v-if="shifou" @click="shifou=false">
            <div class="beijing_box2" @click.stop>
                <!-- <p class="box_con">您当前流程暂未编辑，请编辑<router-link to='/appsett'>审批设置</router-link></p> -->
                <p class="box_con"><span>{{danzi_name}}</span>尚未设置审批流程，请联系管理员进行流程设置。</p>
                <p class="foot">
                    <!-- <span @click="shifou=false">取消</span> -->
                    <span @click="shifou=false">我知道了</span>
                    <!-- <router-link to='/appsett'>确定</router-link> -->
                </p>
            </div>
        </div>
        <div class="beijing" v-if="tongzhi">
            <!-- <div class="beijing_box2" @click.stop>
                <div class="box_con">
                    <p>{{tanchuang_tongzhi.staff_name}}<span :style="tanchuang_tongzhi.accept_status==1?'font-size:0.12rem;color:#67C23A;':'color:#E6A23C;font-size:0.12rem;'">{{tanchuang_tongzhi.accept_status==1?'已接受':'已拒绝'}}</span>客户：{{tanchuang_tongzhi.cust_name}}</p>
                </div>
                <p class="foot">
                    <span @click="dianji_zhidao_le">我知道了</span>
                </p>
            </div> -->
            <div class="beijing_box4" @click.stop>
                <i class="el-icon-error" @click="dianji_zhidao_le"></i>
                <p class="beijing_box4_tit">您向员工{{tanchuang_tongzhi.staff_name}}指派的客户:</p>
                <ul>
                    <li v-for="(i,index) in tanchuang_tongzhi.list" :key="index">
                        <span>{{i.cust_name}}:</span>
                        <span v-for="(it,inde) in jieshou_jujue_list" :key="inde">
                            <img :src="Number(i.accept_status)-1==inde?require('../../assets/me/xuan_zi.png'):require('../../assets/me/weixuan_zi.png')" alt="">
                        {{it}}</span>
                    </li>
                </ul>
                <p class="foot">
                    <span @click="dianji_zhidao_le">我知道了</span>
                </p>
            </div>
        </div>
        <div class="beijing" @click='youce_xiangqing=false' v-if="youce_xiangqing">
            <div class="youce_box" @click.stop >
                <div class="yanjing">
                    <table>
                        <tr>
                            <td>成交时间</td>
                            <td>成交金额({{wenzi_jine_zhuanhua(right_zhanshi.chengjiao_jine)}})</td>
                            <td>退回次数</td>
                            <td>停留时长</td>
                        </tr>
                        <tr>
                            <td>{{right_zhanshi.chengjiao_sj}}</td>
                            <td>{{jine_zhuanhua(right_zhanshi.chengjiao_jine)}}</td>
                            <td>{{right_zhanshi.tuihui_cishu}}</td>
                            <td>{{right_zhanshi.tingliu_shichang}}
                                <span :class="right_zhanshi.color==0?'green':(right_zhanshi.color==1?'yellow':'red')"></span>
                            </td>
                        </tr>
                    </table>
                    <img src="../../assets/me/eye.png" @click="dakai_danju(xiangxi_xinxi)" alt="">
                </div>
                <div class="youce_xiangxi_xinxi" v-for='(item,idx) in xiangxi_xinxi' :key="idx">
                    <!-- {{idx+1}}、 -->
                    <p class="xiangxi_tit" @click="xiangqing_zhankai=!xiangqing_zhankai">详细信息<i :class="xiangqing_zhankai?'el-icon-caret-bottom':'el-icon-caret-left'"></i></p>
                    <transition name="el-zoom-in-top">
                        <div v-show="xiangqing_zhankai" class="transition-box">
                            <!-- {{xiangxi_xinxi}} -->
                            <p v-if="item.cust_name!==undefined&&item.cat_name==null">客户名称：{{item.cust_name}}</p>
                            <p v-if="item.file_no!==undefined&&item.cat_name==null">归档号：{{item.file_no}}</p>
                            <p v-if='item.staff_name!==undefined&&item.cat_name==null'>负责人：{{item.staff_name}}</p>
                            <p v-if="item.dept_name!==undefined&&item.cat_name==null">部门：{{item.dept_name}}</p>
                            <div>
                                <ul>
                                    <li v-for='(i,index) in item.contract_detail' :key="index">
                                        <p>决策人({{index+1}})</p>
                                        <p v-if="i.name!==undefined">姓名：{{i.name}}</p>
                                        <p v-if="i.sex!==undefined">性别：{{i.sex}}</p>
                                        <p v-if="i.dept!==undefined">部门：{{i.dept}}</p>
                                        <p v-if="i.post!==undefined">职务：{{i.post}}</p>
                                        <p v-if="i.fixed_phone!==undefined">座机：{{i.fixed_phone}}</p>
                                        <p v-if="i.mobile!==undefined">手机：{{i.mobile}}</p>
                                        <p v-if="i.birthday!==undefined&&i.birthady!=undefined">生日：{{i.birthday}}</p>
                                        <p v-if="i.interest!==undefined">爱好：{{i.interest}}</p>
                                        <p v-if="i.qq!==undefined">QQ：{{i.qq}}</p>
                                        <p v-if="i.wechat!==undefined">微信：{{i.wechat}}</p>
                                        <p v-if="i.email!==undefined">邮箱：{{i.email}}</p>
                                        <p v-if="i.card!==undefined">名片：<img @click="tupian_dianji(i.card)" class="tupian_kuang" :src="i.card" alt=""></p>
                                    </li>
                                </ul>
                                <p v-if="item.province!==undefined&&item.cat_name!==null">省份：{{item.province}}</p>
                                <p v-if="item.city!==undefined&&item.cat_name!==null">城市：{{item.city}}</p>
                                <p v-if="item.industry!==undefined&&item.cat_name!==null">行业：{{item.industry}}</p>
                                <p v-if="item.source!==undefined&&item.cat_name!==null">客户来源：{{item.source}}</p>
                                <p v-if="item.level!==undefined&&item.cat_name!==null">客户等级：{{item.level}}</p>
                                <p v-if="item.cat_name!==undefined&&item.cat_name!==null">客户标签：{{item.cat_name}}</p>
                                <p v-if="item.biz_addr!==undefined&&item.cat_name!==null">经营地址：{{item.biz_addr}}</p>
                                <p v-if="item.rel_p_com!==undefined&&item.cat_name!==null">母公司名称：{{item.rel_p_com}}</p>
                                <p v-if="item.register_addr!==undefined&&item.cat_name!==null">注册地址：{{item.register_addr}}</p>
                                <p v-if="item.legal_name!==undefined&&item.cat_name!==null">法定代表人：{{item.legal_name}}</p>
                                <p v-if="item.uscc!=''&&item.cat_name!==null">营业执照号：{{item.uscc}}</p>
                                <p v-if="item.register_capital!==undefined&&item.cat_name!==null">注册资本：{{item.register_capital}}</p>
                                <p v-if="item.register_date!==undefined&&item.cat_name!==null">成立日期：{{item.register_date}}</p>
                                <p v-if="item.biz_term!==undefined&&item.cat_name!==null">营业期限：{{item.biz_term}}</p>
                                <p v-if="item.biz_scope!==undefined&&item.cat_name!==null">营业范围：{{item.biz_scope}}</p>
                                <p v-if="item.biz_lic_pic!==undefined&&item.cat_name!==null">营业执照：<img @click="tupian_dianji(item.biz_lic_pic)" class="tupian_kuang" :src="item.biz_lic_pic" alt=""></p>
                            </div>
                            <ul>
								<li v-for="(i,index) in item.zidingyi" :key="index">
									<!-- <p v-if="i.value.length!=0">{{i.name}}：{{i.value}}</p> -->
									<div v-if="i.type=='ddattachment'">
										<p v-if="i.fujian_list!=undefined&&i.fujian_list.length!=0">附件：</p>
										<ul>
											<li class="keyi_dianji" v-for="(it,inde) in i.fujian_list" :key="inde">
												<el-tooltip class="item" effect="dark" content="点击下载查看" placement="top">
													<p class="fujian_xiazai_kuang" @click="dianji_xiazai_fujian(it)">
														<i class="el-icon-paperclip" style="margin-right:0.1rem;"></i>
														{{it.name}}
													</p>
												</el-tooltip>
											</li>
										</ul>
									</div>
									<!-- 关联审批单 -->
									<div class="rqi_kuang_xiangqiang" v-if="i.type=='assappform'">
										<p>{{i.name}}：</p>
										<ul>
											<li class="keyi_dianji" @click="biaodan_xiangqing(it)" v-for="(it,inde) in i.liebiao" :key="inde">
												<p class="guanlian_kuang">
													<i class="el-icon-connection" style="margin-right:0.1rem;"></i>
													{{it.flow_desc}}:{{it.start_time}}
												</p>
											</li>
										</ul>
										<!-- <BiaodanXiangqing ref='biaodan_xiangqing_zhanshi'></BiaodanXiangqing> -->
									</div>
									<!-- <div v-if="i.type=='province'" @click="ceshi_diqu2(i)" class="rqi_kuang_xiangqiang">
										{{i.name}}：
										<v-distpicker @selected="onSelected2" :province='i.value_id' :city='i.value_ids' :area='i.value_idss'></v-distpicker>
									</div> -->
									<div v-if="i.type=='tablefield'">
										<div v-for="(it,inde) in i.mingxi_list" :key="inde">
											{{i.name+'('+(inde+1)+')'}}：
											<div v-for="(ite,ind) in it.mingxi_list" :key="ind">
												<p v-if="ite.type=='ddphotofield'&&ite.img_url!=undefined&&ite.img_url.length!=0">{{ite.name}}：<img @click="tupian_dianji(ite.img_url)" class="tupian_kuang" :src="ite.img_url" alt=""></p>
												<p v-if="ite.type!='ddattachment'&&ite.type!='ddphotofield'&&ite.type!='assappform'&&ite.type!='product'&&ite.value!=undefined&&ite.value.length!=0">
													{{ite.name}}：{{ite.value}}
												</p>
												<div v-if="ite.type=='product'&&ite.value!=undefined&&ite.value.length!=0">
													<p>{{ite.name}}：{{ite.value}}</p>
													<p>产品编号：{{ite.liebiao[0].prod_code}}</p>
													<p>产品类型：{{ite.liebiao[0].cat_name}}</p>
													<p>规格：{{ite.liebiao[0].spec}}</p>
													<p>型号：{{ite.liebiao[0].type}}</p>
													<p>单位：{{ite.liebiao[0].unit}}</p>
												</div>
											</div>
										</div>
									</div>
									<p v-if="i.type=='ddphotofield'&&i.img_url!=undefined&&i.img_url.length!=0">{{i.name}}：<img @click="tupian_dianji(i.img_url)" class="tupian_kuang" :src="i.img_url" alt=""></p>
									<p v-if="i.type!='ddattachment'&&i.type!='ddphotofield'&&i.type!='assappform'&&i.type!='tablefield'&&i.type!='product'&&i.value!=undefined&&i.value.length!=0">
										{{i.name}}：{{i.value}}
									</p>
									<div v-if="i.type=='product'&&i.value!=undefined&&i.value.length!=0">
										<p>{{i.name}}：{{i.value}}</p>
										<p>产品编号：{{i.liebiao[0].prod_code}}</p>
										<p>产品类型：{{i.liebiao[0].cat_name}}</p>
										<p>规格：{{i.liebiao[0].spec}}</p>
										<p>型号：{{i.liebiao[0].type}}</p>
										<p>单位：{{i.liebiao[0].unit}}</p>
									</div>
								</li>
							</ul>
                        </div>
                    </transition>
                </div>
                <div style="margin-top:0.3rem;" class="youce_xiangxi_xinxi">
                    <p class="xiangxi_tit" @click="xiangqing_kehu_zhankai=!xiangqing_kehu_zhankai">客户操作日志<i :class="xiangqing_kehu_zhankai?'el-icon-caret-bottom':'el-icon-caret-left'"></i></p>
                    <transition name="el-zoom-in-top">
                        <div v-show="xiangqing_kehu_zhankai" class="transition-box">
                            <ul>
                                <li v-for="(i,index) in zhanshi_lishi" :key="index">
                                    <p v-if="i.type=='1'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被系统分配给{{i.dept_name}}{{i.staff_name}}；</p>
                                    <p v-if="i.type=='2'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.dept_name}}{{i.staff_name}}退回公海池；</p>
                                    <p v-if="i.type=='3'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_dept_name}}{{i.operate_user_name}}从{{i.dept_name}}冷冻仓激活到公海池；</p>
                                    <p v-if="i.type=='4'">{{index+1}}、{{i.operate_time}}-{{i.dept_name}}{{i.staff_name}}接收{{i.operate_dept_name}}{{i.operate_user_name}}指派的{{i.cust_name}}；</p>
                                    <p v-if="i.type=='5'">{{index+1}}、{{i.operate_time}}-{{i.dept_name}}{{i.staff_name}}拒绝{{i.operate_dept_name}}{{i.operate_user_name}}指派的{{i.cust_name}}；</p>
                                    <p v-if="i.type=='6'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_dept_name}}{{i.operate_user_name}}录入公海池；</p>
                                    <p v-if="i.type=='7'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_dept_name}}{{i.operate_user_name}}指派给{{i.dept_name}}{{i.staff_name}}；</p>
                                    <p v-if="i.type=='8'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_dept_name}}{{i.operate_user_name}}录入已分配；</p>
                                    <p v-if="i.type=='9'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_dept_name}}{{i.operate_user_name}}修改；</p>
                                    <p v-if="i.type=='10'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被系统录入</p>
                                    <p v-if="i.type=='11'">{{index+1}}、{{i.operate_time}}-{{i.dept_name}}{{i.cust_name}}被系统自动退回</p>
                                    <p v-if="i.type=='12'">{{index+1}}、{{i.operate_time}}-{{i.dept_name}}{{i.cust_name}}被系统自动从公海池退回冷冻仓</p>
                                    <!-- <p v-if="i.type=='4'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_user_name}}指派给{{i.dept_name}}{{i.staff_name}}，{{i.accept_time}}{{i.staff_name}}已接收；</p>
                                    <p v-if="i.type=='5'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_user_name}}指派给{{i.dept_name}}{{i.staff_name}}，{{i.accept_time}}{{i.staff_name}}已拒绝；</p>
                                    <p v-if="i.type=='7'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_user_name}}指派给{{i.dept_name}}{{i.staff_name}}，待{{i.staff_name}}接受；</p>
                                    <p v-if="i.type=='6'&&i.operate_user_id=='sys'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被系统录入公海池；</p>
                                    <p v-if="i.type=='6'&&i.operate_user_id!='sys'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}{{i.dept_name}}{{i.operate_user_name}}被系统录入公海池；</p> -->
                                </li>
                            </ul>
                        </div>
                    </transition>
                </div>
                <div style="margin-top:0.3rem;" class="youce_xiangxi_xinxia">
                    <p class="xiangxi_tit" @click="xiangqing_kehu_fujian=!xiangqing_kehu_fujian">附件下载<i :class="xiangqing_kehu_fujian?'el-icon-caret-bottom':'el-icon-caret-left'"></i></p>
                    <transition name="el-zoom-in-top">
                        <div v-show="xiangqing_kehu_fujian" class="transition-box">
                            <p class="transition-box_tit">
                                选择归档号：
                            </p>
                            <p class='transition-box_tit_file'>
                                <span v-for="(i,index) in fujian_list" @click="dianji_guidang_hao(index)" :key="index"><img :src="zhanshi_guidang_hao==index?require('../../assets/me/xuan_zi.png'):require('../../assets/me/weixuan_zi.png')" alt="">{{i.file_no}}</span>
                            </p>
                            <div class="fujian_con" v-if="fujian_list.length!=0&&fujian_list[zhanshi_guidang_hao].con_annex!=undefined">
                                <p class="fujian_con_tit">合同附件:</p>
                                <ul>
                                    <li v-for="(ita,ind) in fujian_list[zhanshi_guidang_hao].con_annex" :key="ind">
                                        <p>
                                            <img @click="ita.zhi=!ita.zhi" :src="ita.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                                            {{ita.annex_name}}
                                        </p>
                                        <span @click="fujian_xiazai(ita)">下载</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="fujian_con" v-if="fujian_list.length!=0&&fujian_list[zhanshi_guidang_hao].inv_annex!=undefined">
                                <p class="fujian_con_tit">发票附件:</p>
                                <ul>
                                    <li v-for="(ita,ind) in fujian_list[zhanshi_guidang_hao].inv_annex" :key="ind">
                                        <p>
                                            <img @click="ita.zhi=!ita.zhi" :src="ita.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                                            {{ita.annex_name}}
                                        </p>
                                        <span @click="fujian_xiazai(ita)">下载</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="fujian_con" v-if="fujian_list.length!=0&&fujian_list[zhanshi_guidang_hao].rec_annex!=undefined">
                                <p class="fujian_con_tit">回款附件:</p>
                                <ul>
                                    <li v-for="(ita,ind) in fujian_list[zhanshi_guidang_hao].rec_annex" :key="ind">
                                        <p>
                                            <img @click="ita.zhi=!ita.zhi" :src="ita.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                                            {{ita.annex_name}}
                                        </p>
                                        <span @click="fujian_xiazai(ita)">下载</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="fujian_con" v-if="fujian_list.length!=0&&fujian_list[zhanshi_guidang_hao].deliver_annex!=undefined">
                                <p class="fujian_con_tit">交付附件:</p>
                                <ul>
                                    <li v-for="(ita,ind) in fujian_list[zhanshi_guidang_hao].deliver_annex" :key="ind">
                                        <p>
                                            <img @click="ita.zhi=!ita.zhi" :src="ita.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                                            {{ita.annex_name}}
                                        </p>
                                        <span @click="fujian_xiazai(ita)">下载</span>
                                    </li>
                                </ul>
                            </div>
                            <p class="fujian_foot" v-if="fujian_list.length!=0&&(fujian_list[zhanshi_guidang_hao].deliver_annex!=undefined||fujian_list[zhanshi_guidang_hao].con_annex!=undefined||fujian_list[zhanshi_guidang_hao].rec_annex!=undefined||fujian_list[zhanshi_guidang_hao].inv_annex!=undefined)">
                                <span @click="dianji_quanxuan_fujian">
                                    <img :src="fujian_quanxuan?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                                    全选
                                </span>
                                <span @click="dianji_piliang_xiazi">批量下载</span>
                            </p>
                            <p class="no_fujian" v-if="fujian_list.length==0||(fujian_list[zhanshi_guidang_hao].deliver_annex==undefined&&fujian_list[zhanshi_guidang_hao].con_annex==undefined&&fujian_list[zhanshi_guidang_hao].rec_annex==undefined&&fujian_list[zhanshi_guidang_hao].inv_annex==undefined)">该归档号下没有可以下载的附件</p>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
		<yanjing ref="eye"></yanjing>
		<tupian ref="bigphote"></tupian>
    </div>
</template>

<script>
import { showLoading, hideLoading } from '../../Js/loading'
import BiaodanXiangqing from '../../components/biaodan/biaodanxq'
import yanjing from '../../page/pagea/yanjing'
import tupian from '../../components/bigphote/bigphote'
import { query_all_module_sale_userid, query_form_replace_write, query_customer_else_info, query_cust_allot_log_list, customer_annex_download, query_form_use_power_list, query_all_module_read_power_list, query_staff_list_of_dept, get_staff_list_like, query_ent_dept_list, query_dept_staff_list, query_ent_dept_list_aim, query_customer_allot_rule_detail, query_customer_mng_log_list, query_customer_detail, query_user_info, update_customer_allot_log_read_flag, query_customer_allot_log_list_feed_back, allot_customer, query_after_user_info_by_user_id, query_staff_dept_list, query_flow_form_have_set, query_flow_info_all, query_customer_list_sea, delete_customer_sea } from '../../api/api.js'
export default {
  name: 'highseas',
  data () {
    return {
      biaotou2_list: [],
      gonghai_sj_zhi: '',
      gonghai_sj_list: [
        {
          label: '录入时间',
          value: '1'
        },
        {
          label: '激活时间',
          value: '2'
        },
        {
          label: '成交时间',
          value: '3'
        }
      ],
      gonghai_chi_time: '',
      tableData2: [],
      biao2_tit: [
        {
          name: '客户',
          con: 'cust_name'
        },
        {
          name: '客户等级',
          con: 'level'
        },
        {
          name: '客户标签',
          con: 'tag_name'
        },
        {
          name: '部门',
          con: 'dept_name'
        },
        {
          name: '决策人',
          con: 'rel_name'
        },
        {
          name: '客户来源',
          con: 'source'
        },
        {
          name: '行业',
          con: 'industry'
        },
        {
          name: '省份',
          con: 'province'
        }
      ],
      // {
      //         name:'成交时间',
      //         con:'date_trans'
      //     },
      //     {
      //         name:'成交金额',
      //         con:'chengjiao_jine'
      //     },
      //     {
      //         name:'退回次数',
      //         con:'back_num'
      //     },
      //     {
      //         name:'停留时长',
      //         con:'sj_time'
      //     },
      xianshi_quanbu: false,
      zhanshi_list: [
        {
          name: '客户',
          con: 'cust_name',
          zhi: false
        },
        // {
        //     name:'归档号',
        //     con:'file_no',
        //     zhi:false,
        // },
        {
          name: '部门',
          con: 'dept_name',
          zhi: false
        },
        {
          name: '决策人',
          con: 'rel_name',
          zhi: false
        },
        {
          name: '省份',
          con: 'province',
          zhi: false
        },
        {
          name: '城市',
          con: 'city',
          zhi: false
        },
        {
          name: '行业',
          con: 'industry',
          zhi: false
        },
        {
          name: '客户来源',
          con: 'source',
          zhi: false
        },
        {
          name: '客户等级',
          con: 'level',
          zhi: false
        },
        {
          name: '客户标签',
          con: 'tag_name',
          zhi: false
        },
        {
          name: '经营地址',
          con: 'biz_addr',
          zhi: false
        },
        {
          name: '母公司名称',
          con: 'rel_p_com',
          zhi: false
        },
        {
          name: '注册地址',
          con: 'register_addr',
          zhi: false
        },
        {
          name: '法定代表人',
          con: 'legal_name',
          zhi: false
        },
        {
          name: '营业执照号',
          con: 'uscc',
          zhi: false
        },
        {
          name: '注册资本',
          con: 'register_capital',
          zhi: false
        },
        {
          name: '成立日期',
          con: 'register_date',
          zhi: false
        },
        {
          name: '营业期限',
          con: 'biz_term',
          zhi: false
        },
        {
          name: '营业范围',
          con: 'biz_scope',
          zhi: false
        }
      ],
      // {
      //     name:'成交时间',
      //     con:'date_trans',
      //     zhi:false,
      // },
      // {
      //     name:'成交金额',
      //     con:'chengjiao_jine',
      //     zhi:false,
      // },
      // {
      //     name:'退回次数',
      //     con:'back_num'
      // },
      // {
      //     name:'停留时长',
      //     con:'sj_time'
      // },
      xianshi_shezhi: false,
      xuan_zhong: '',
      sousuo_text: '',
      xianshi_xiang_list: [],
      shifou: false,
      danzi_name: '',
      zhipai_liebiao: false,
      renyuan_list: [],
      yixuan_ren_list: [],
      tongzhi: false,
      tanchuang_tongzhi: {},
      youce_xiangqing: false,
      xiangxi_xinxi: [],
      xiangqing_zhankai: false,
      xiangqing: {
        flow_id: '7TvJHPyovM'
      },
      xiangqing_kehu_zhankai: false,
      zhanshi_lishi: [],
      sousuo_text2: '',
      chaosong_qiye_renyuan_list: [],
      mianbao_chaosong_list: [
        {
          name: '',
          dept_id: '0'
        }
      ],
      renyuan_chaosong_quanxuan: false,
      chaosong_renyuan_list_xuan: [],
      chaosong_qiye_renyuan_list: [],
      chaosong_qiye_bumen_list: [],
      shaixuan_ren: false,
      zhuguan: false,
      quanbu_kejian: false,
      lv1: '',
      lv2: '',
      yellow1: '',
      yellow2: '',
      red: '',
      dangqian_yeshu: sessionStorage.getItem('cbr_gonghai_chi') != undefined && sessionStorage.getItem('cbr_gonghai_chi').length != 0 ? sessionStorage.getItem('cbr_gonghai_chi') : 1,
      dangqian_zongshu: 0,
      zhuguan_liyuan: false,
      xiangqing_kehu_fujian: false,
      zhanshi_guidang_hao: '0',
      fujian_quanxuan: false,
      fujian_list: [],
      // 2020/6/4补充
      jieshou_jujue_list: ['接受', '拒绝'],
      tiaoshu: 10,
      ziguan_liyuan: false,
      right_zhanshi: {
        chengjiao_sj: '',
        chengjiao_jine: '',
        tuihui_cishu: '',
        tingliu_shichang: '',
        color: ''
      },

      jichuxinxi: {},
      shifou_have_daixie: false // 用来判断是否有代写权限
    }
  },
  components: { BiaodanXiangqing, yanjing, tupian },
  mounted () {
  },
  created () {
    sessionStorage.removeItem('mlbb_one_shezhi')
    sessionStorage.removeItem('mlbb_four_shezhi')
    sessionStorage.removeItem('mlbb_shenpi_zhengyi')
    sessionStorage.removeItem('mlbb_biaodan_sheji')
    sessionStorage.removeItem('mlbb_liucheng_shezhi')
    sessionStorage.removeItem('mlbb_liucheng_shezhi_flowPermission')
    sessionStorage.removeItem('mlbb_liucheng_shezhi_tableId')
    sessionStorage.removeItem('mlbb_liucheng_shezhi_list')

    sessionStorage.removeItem('mlbb_shenpi_biaodan')
    sessionStorage.removeItem('mlbb_shenpi_biaodan_2')
    sessionStorage.removeItem('mlbb_shenpi_biaodan_3')
    sessionStorage.removeItem('mlbb_shenpi_biaodan_4')
    sessionStorage.removeItem('mlbb_shenpi_biaodan_5')
    this.chushi_chaxun()
    this.jichu()
    this.mianbao_chaosong_list[0].name = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name
  },
  watch: {
    xianshi_xiang_list: {
      handler (newValue, oldValue) {
        if (this.xianshi_xiang_list.length == this.zhanshi_list.length) {
          this.xianshi_quanbu = true
        } else {
          this.xianshi_quanbu = false
        }
      },
      deep: true
    },
    youce_xiangqing () {
      if (!this.youce_xiangqing) {
        this.zhanshi_lishi = []
        this.xiangqing_kehu_zhankai = false
        this.right_zhanshi = {
          chengjiao_sj: '',
          chengjiao_jine: '',
          tuihui_cishu: '',
          tingliu_shichang: '',
          color: ''
        }
      }
    },
    xianshi_shezhi: {
      handler (newValue, oldValue) {
        if (this.xianshi_shezhi == false) {
          this.zhanshi_list.map(item => { item.zhi = false })
          this.xianshi_xiang_list = []
        }
      }
    },
    zhipai_liebiao () {
      if (!this.zhipai_liebiao) {
        this.chaosong_renyuan_list_xuan = []
        this.chaosong_qiye_renyuan_list = []
        this.renyuan_chaosong_quanxuan = false
        this.mianbao_chaosong_list = [
          {
            name: JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name,
            dept_id: '0'
          }
        ]
      }
    },
    dangqian_yeshu () {
      sessionStorage.setItem('cbr_gonghai_chi', JSON.stringify(this.dangqian_yeshu))
      this.liebiao_jiekou()
    },
    tiaoshu () {
      this.liebiao_jiekou()
    },
    fujian_list: {
      handler (newValue, oldValue) {
        this.panduan_fujian_quanxuan()
      },
      deep: true
    }
  },
  methods: {
    chushi_chaxun () { // 查询他有没有代写权限
      query_form_replace_write({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          const _this = this
          console.log(date)
          if (date != undefined && date != null && date.length != 0) {
            date.forEach(item => {
              if (item.staff_id == _this.$jichuxinxi().user_id) {
                _this.shifou_have_daixie = true
              }
            })
          } else {
            this.shifou_have_daixie = false
          }
        } else if (res.data.code == 500) {}
      })
    },
    jichu () {
      // let quanxian=''
      // if(this.$func.quanxian()==='0'){
      //     quanxian='0'
      // }else{
      //     let data=this.$func.quanxian()
      //     quanxian=data.map((item,index)=>{if(item.module_id==2){return item.read_only}})
      // }
      // if(quanxian=='1'){
      // this.biaotou2_list=['导入/导出','显示设置'];
      // }else if(quanxian=='2'){
      // }

      query_all_module_read_power_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(zong => {
        console.log(zong)
        if (zong.data.code == 200) {
          const zongdata = JSON.parse(zong.data.body.data)
          console.log(zongdata)
          if (zongdata.length != 0) {
            this.quanbu_kejian = true
          } else {
            this.quanbu_kejian = false
            this.huoqu_all_mokuai_xiaoshou()
          }
          query_user_info({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              if (res.data.body.staff_level == 1) {
                this.zhuguan_liyuan = true
              }
              if (res.data.body.staff_level == 2) {
                this.ziguan_liyuan = true
              }
              if (res.data.body.dept_manager == 1 || res.data.body.staff_level == 1 || res.data.body.staff_level == 2) {
                this.zhuguan = true
              } else {
                this.zhuguan = false
              }
              // else{
              //     this.biaotou2_list=['新建','导入/导出','显示设置'];
              // }
              query_customer_allot_rule_detail({
                data: {
                  ent_id: this.$ent_id()
                }
              }).then(rzs => {
                console.log(rzs)
                if (rzs.data.code == 200) {
                  if (rzs.data.body != null) {
                    const data = JSON.parse(rzs.data.body.data)
                    console.log(data)
                    const lv = data.stay_time_green.split(',')
                    const yellow = data.stay_time_yellow.split(',')
                    const lv1 = Number(lv[0])
                    const lv2 = Number(lv[1])
                    const yellow1 = Number(yellow[0])
                    const yellow2 = Number(yellow[1])
                    const red = Number(data.stay_time_red)
                    this.lv1 = lv1
                    this.lv2 = lv2
                    this.yellow1 = yellow1
                    this.yellow2 = yellow2
                    this.red = red
                    // quanbu_kejian
                    query_form_use_power_list({
                      data: {
                        ent_id: this.$ent_id(),
                        user_id: this.$jichuxinxi().user_id
                      }
                    }).then(ras => {
                      console.log(ras)
                      if (ras.data.code == 200) {
                        const dzta = JSON.parse(ras.data.body.data)
                        console.log(dzta)
                        const zhan = []
                        dzta.forEach(item => {
                          // if(item.flow_id=='7TvJHPyovM'){
                          //     zhan.push('1111')
                          // }
                          item.children.forEach(it => {
                            if (it.flow_id == '7TvJHPyovM') {
                              zhan.push('1111')
                            }
                          })
                        })
                        console.log(zhan)
                        if (zhan.length != 0) {
                          if (data.flag_spec_allot == 1) {
                            this.biaotou2_list = ['指派', '新建', '编辑', '显示设置']
                          } else {
                            this.biaotou2_list = ['新建', '编辑', '显示设置']
                          }
                          if (this.zhuguan_liyuan || this.ziguan_liyuan) {
                            this.biaotou2_list.push('导入')
                          }
                          this.liebiao_jiekou()
                          this.zhipai_tongzhi()
                        } else {
                          if (data.flag_spec_allot == 1) {
                            this.biaotou2_list = ['指派', '显示设置']
                          } else {
                            this.biaotou2_list = ['显示设置']
                          }
                          if (this.zhuguan_liyuan || this.ziguan_liyuan) {
                            this.biaotou2_list.push('导入')
                          }
                          this.liebiao_jiekou()
                          this.zhipai_tongzhi()
                        }
                      } else if (ras.data.code == 500) {}
                    })
                  } else {
                    this.$message({
                      message: '还未分配规则',
                      type: 'warning'
                    })
                  }
                } else if (rzs.data.code == 500) {}
              })
            } else if (res.data.code == 500) {}
          })
        } else if (zong.data.code == 500) {}
      })
    },
    // 获取是否有销售权限
    huoqu_all_mokuai_xiaoshou () {
      query_all_module_sale_userid({
        data: {
          ent_id: this.$ent_id(),
          read_flag: '1'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          if (date != null) {
            console.log(date)
            let ids = []
            if (date.staff_ids != undefined) {
              ids = date.staff_ids.split(',')
            }
            console.log(ids)

            ids.forEach(item => {
              if (item == this.$jichuxinxi().user_id) {
                this.quanbu_kejian = true
              }
            })
          }
        } else if (res.data.code == 500) {}
      })
    },
    liebiao_jiekou () { // 公海池  列表
      showLoading()
      this.gonghai_sj_zhi = this.gonghai_sj_zhi.length == 0 ? '1' : this.gonghai_sj_zhi
      const date = this.gonghai_chi_time
      let start_time = null
      let end_time = null
      if (date != null && date != undefined && date.length != 0) {
        start_time = date[0].getFullYear() + '-' + this.$func.pa(date[0].getMonth() + 1) + '-' + this.$func.pa(date[0].getDate())
        end_time = date[1].getFullYear() + '-' + this.$func.pa(date[1].getMonth() + 1) + '-' + this.$func.pa(date[1].getDate())
      }
      const obj = {
        ent_id: this.$ent_id(),
        search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null,
        // time_type:this.gonghai_sj_zhi.length!=0?this.gonghai_sj_zhi:null,
        // date_start:start_time,
        // date_end:end_time,
        user_id: this.$jichuxinxi().user_id,
        page_size: this.tiaoshu + '',
        page_index: this.dangqian_yeshu + ''
      }
      query_customer_list_sea({ data: obj }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          date.list.map(item => {
            if (Number(item.stay_long) >= Number(this.lv1) && Number(item.stay_long) <= Number(this.lv2)) {
              this.$set(item, 'sj_time', '0')
              this.$set(item, 'time', Number(this.lv1) + '-' + Number(this.lv2) + '天')
            } else if (Number(item.stay_long) > Number(this.yellow1) && Number(item.stay_long) <= Number(this.yellow2)) {
              this.$set(item, 'sj_time', '1')
              this.$set(item, 'time', Number(this.yellow1) + '-' + Number(this.yellow2) + '天')
            } else if (Number(item.stay_long) > Number(this.red)) {
              this.$set(item, 'sj_time', '2')
              this.$set(item, 'time', Number(this.red) + '天以上')
            }
            let chengjiao_jine = ''
            if (item.amt_trans != undefined) {
              const jine_list = item.amt_trans.split(',')
              const jine_zhan = []
              jine_list.forEach(it => {
                jine_zhan.push(this.$qianwei(Number(it).toFixed(2)))
              })
              chengjiao_jine = jine_zhan.join(',')
            }
            this.$set(item, 'chengjiao_jine', chengjiao_jine)
          })
          this.dangqian_zongshu = date.total
          this.tableData2 = date.list
          //console.log(this.tableData2)
          hideLoading()
        } else if (res.data.code == 500) {}
      })
    },
    dianji_yeshu (val) {
      this.dangqian_yeshu = val
    },
    dianji_tiaoshu (val) {
      console.log(`每页 ${val} 条`)
      this.tiaoshu = val
      this.dangqian_yeshu = 1
    },
    dianji_fanhui () {
      this.$router.push('/cusman')
    },
    // 已分配里点击事件
    dianji_fenpei (i) {
      console.log(i)
      if (i == '显示设置') {
        this.biao2_tit.forEach(item => {
          this.zhanshi_list.forEach(ite => {
            if (item.con == ite.con) {
              this.xuanze_danxuan(ite)
            }
          })
        })
        this.xianshi_shezhi = true
      } else if (i == '导入') {
        this.$router.push('/importexport')
      } else if (i == '新建') {
        // query_flow_form_have_set({data:{
        //     ent_id:this.$ent_id(),
        //     flow_id:'7TvJHPyovM'
        // }}).then(ras=>{
        //     console.log(ras)
        //     if(ras.data.code==10245){
        query_flow_info_all({
          data: {
            ent_id: this.$ent_id(),
            flow_id: '7TvJHPyovM'
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = res.data.body.form_str
            console.log(date)
            const data = []
            for (let a = 0; a < date.length; a++) {
              if (date[a].defaultImportant != undefined) {
                date[a].defaultImportant = date[a].defaultImportant != 'false'
              }
              if (date[a].defaultPrint != undefined) {
                date[a].defaultPrint = date[a].defaultPrint != 'false'
              }
              if (date[a].defaultOptions != undefined) {
                date[a].defaultOptions = JSON.parse(date[a].defaultOptions)
              }
              if (date[a].gongshi != undefined) {
                date[a].gongshi = JSON.parse(date[a].gongshi)
              }
              if (date[a].supportSetting != undefined) {
                date[a].supportSetting = JSON.parse(date[a].supportSetting)
              }
              if (date[a].components != undefined) {
                date[a].components = JSON.parse(date[a].components)
                for (let b = 0; b < date[a].components.length; b++) {
                  if (date[a].components[b].defaultImportant != undefined) {
                    if (typeof (date[a].components[b].defaultImportant) === 'string') {
                      date[a].components[b].defaultImportant = date[a].components[b].defaultImportant != 'false'
                    }
                  }
                  if (date[a].components[b].defaultPrint != undefined) {
                    if (typeof (date[a].components[b].defaultPrint) === 'string') {
                      date[a].components[b].defaultPrint = date[a].components[b].defaultPrint != 'false'
                    }
                  }
                  // typeof(str)=='string'
                  if (date[a].components[b].defaultOptions != undefined) {
                    if (typeof (date[a].components[b].defaultOptions) === 'string') {
                      date[a].components[b].defaultOptions = JSON.parse(date[a].components[b].defaultOptions)
                    }
                  }
                  if (date[a].components[b].gongshi != undefined && date[a].components[b].gongshi.length != 0) {
                    if (typeof (date[a].components[b].gongshi) === 'string') {
                      console.log(date[a].components[b].gongshi)
                      date[a].components[b].gongshi = JSON.parse(date[a].components[b].gongshi)
                    }
                  }
                  if (date[a].components[b].supportSetting != undefined && date[a].components[b].supportSetting.length != 0) {
                    if (typeof (date[a].components[b].supportSetting) === 'string') {
                      console.log(date[a].components[b].supportSetting)
                      date[a].components[b].supportSetting = JSON.parse(date[a].components[b].supportSetting)
                    }
                  }
                }
              }
              if (date[a].componentName != 'contacts' && date[a].defaultLable != '负责人') {
                data.push(date[a])
              }
            }
            const obj = {
              flow_id: '7TvJHPyovM',
	                                flow_name: '客户录入单'
            }
            this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan', obj, '暂时')
            this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_2', data, '暂时')
            this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_3', '公海池', '暂时')
            this.$router.push('/usetable')
          } else if (res.data.code == 500) {}
        })
        //     }else if(ras.data.code==10246){
        //         this.shifou=true
        //         this.danzi_name='客户录入单'
        //     }
        // })
      } else if (i == '指派') {
        if (this.xuan_zhong.length != 0) {
          // query_ent_dept_list_aim({data:{
          //     ent_id:this.$ent_id(),
          //     user_id:this.$jichuxinxi().user_id
          // }}).then(res=>{
          //     console.log(res)
          //     if(res.data.code==10168){
          //         let date=JSON.parse(res.data.body.data)
          //         console.log(date)
          //         date.forEach(item=>{
          //             this.$set(item,'dept_name',item.label)
          //         })
          //         this.chaosong_qiye_bumen_list=date
          //     }else if(res.data.code==10169){}
          // })
          const quchong = this.$func.Es5duplicate(this.xuan_zhong, 'dept_id')
          if (quchong.length != 0) {
            // query_staff_list_of_dept({data:{
            //     ent_id:this.$ent_id(),
            //     dept_id:this.xuan_zhong[0].dept_id,
            //     active:'1'
            // }}).then(res=>{
            //     console.log(res)
            //     if(res.data.code==200){
            //         let date=JSON.parse(res.data.body.data)
            //         console.log(date)
            //         this.chaosong_qiye_bumen_list=date
            //     }else if(res.data.code==500){}
            // })
            query_ent_dept_list_aim({
              data: {
                ent_id: this.$ent_id(),
                user_id: (this.zhuguan_liyuan || this.ziguan_liyuan) ? null : this.$jichuxinxi().user_id
              }
            }).then(res => {
              console.log(res)
              if (res.data.code == 10168) {
                const date = JSON.parse(res.data.body.data)
                console.log(date)
                date.forEach(item => {
                  this.$set(item, 'dept_name', item.label)
                })
                this.chaosong_qiye_bumen_list = date
              } else if (res.data.code == 10169) {} else if (res.data.code == 10250) {
                this.$message({
                  message: '公海池客户目前不够分配',
                  type: 'warning'
                })
              } else if (res.data.code == 10251) {
                this.$message({
                  message: '公海池客户已超额分配',
                  type: 'warning'
                })
              } else if (res.data.code == 10300) {
                this.$message({
                  message: '企业还没有设置分配规则',
                  type: 'warning'
                })
              }
            })
          } else {
            this.$message({
              message: '请选择客户',
              type: 'warning'
            })
          }
          // query_dept_staff_list({data:{
          //     ent_id:this.$ent_id(),
          //     dept_name:this
          // }})
          this.zhipai_liebiao = true
          // query_after_user_info_by_user_id({data:{
          //     ent_id:this.$ent_id(),
          //     user_id:this.$jichuxinxi().user_id
          // }}).then(res=>{
          //     console.log(res)
          //     if(res.data.code==200){
          //         let date=JSON.parse(res.data.body.data)
          //         console.log(date)
          //         date.forEach(item=>{
          //             this.$set(item,'value_num','-1')
          //             this.$set(item,'value_zhipai',false)
          //         })
          //         // for(let a=0;a<date.length;a++){
          //         //     if(date[a].staff_id==this.$jichuxinxi().user_id){
          //         //         date.splice(a,1)
          //         //         a=a-1
          //         //     }
          //         // }
          //         this.renyuan_list=date

          //     }else if(res.data.code==500){}
          // })
        } else {
          this.$message({
            message: '请选择客户进行指派',
            type: 'warning'
          })
        }
      } else if (i == '编辑') {
        if (this.xuan_zhong.length == 1) {
          query_customer_detail({
            data: {
              ent_id: this.$ent_id(),
              cust_id: this.xuan_zhong[0].cust_id,
              file_no: this.xuan_zhong[0].file_no,
              dept_id: this.xuan_zhong[0].dept_id
            }
          }).then(rds => {
            console.log(rds)
            if (rds.data.code == 200) {
              const xiangqing = JSON.parse(rds.data.body.data)
              console.log(xiangqing)
              if (xiangqing.flow_status == undefined || xiangqing.flow_status == 4 || xiangqing.flow_status == 2 || xiangqing.flow_status == 7) {
                // query_flow_form_have_set({data:{
                //     ent_id:this.$ent_id(),
                //     flow_id:'7TvJHPyovM'
                // }}).then(ras=>{
                //     console.log(ras)
                //     if(ras.data.code==10245){
                query_flow_info_all({
                  data: {
                    ent_id: this.$ent_id(),
                    flow_id: '7TvJHPyovM'
                  }
                }).then(res => {
                  console.log(res)
                  if (res.data.code == 200) {
                    const date = res.data.body.form_str
                    console.log(date)
                    const data = []
                    for (let a = 0; a < date.length; a++) {
                      if (date[a].defaultImportant != undefined) {
                        date[a].defaultImportant = date[a].defaultImportant != 'false'
                      }
                      if (date[a].defaultPrint != undefined) {
                        date[a].defaultPrint = date[a].defaultPrint != 'false'
                      }
                      if (date[a].defaultOptions != undefined) {
                        date[a].defaultOptions = JSON.parse(date[a].defaultOptions)
                      }
                      if (date[a].gongshi != undefined) {
                        date[a].gongshi = JSON.parse(date[a].gongshi)
                      }
                      if (date[a].supportSetting != undefined) {
                        date[a].supportSetting = JSON.parse(date[a].supportSetting)
                      }
                      if (date[a].components != undefined) {
                        date[a].components = JSON.parse(date[a].components)
                        for (let b = 0; b < date[a].components.length; b++) {
                          if (date[a].components[b].defaultImportant != undefined) {
                            if (typeof (date[a].components[b].defaultImportant) === 'string') {
                              date[a].components[b].defaultImportant = date[a].components[b].defaultImportant != 'false'
                            }
                          }
                          if (date[a].components[b].defaultPrint != undefined) {
                            if (typeof (date[a].components[b].defaultPrint) === 'string') {
                              date[a].components[b].defaultPrint = date[a].components[b].defaultPrint != 'false'
                            }
                          }
                          // typeof(str)=='string'
                          if (date[a].components[b].defaultOptions != undefined) {
                            if (typeof (date[a].components[b].defaultOptions) === 'string') {
                              date[a].components[b].defaultOptions = JSON.parse(date[a].components[b].defaultOptions)
                            }
                          }
                          if (date[a].components[b].gongshi != undefined && date[a].components[b].gongshi.length != 0) {
                            if (typeof (date[a].components[b].gongshi) === 'string') {
                              console.log(date[a].components[b].gongshi)
                              date[a].components[b].gongshi = JSON.parse(date[a].components[b].gongshi)
                            }
                          }
                          if (date[a].components[b].supportSetting != undefined && date[a].components[b].supportSetting.length != 0) {
                            if (typeof (date[a].components[b].supportSetting) === 'string') {
                              console.log(date[a].components[b].supportSetting)
                              date[a].components[b].supportSetting = JSON.parse(date[a].components[b].supportSetting)
                            }
                          }
                        }
                      }
                      if (date[a].componentName != 'contacts' && date[a].defaultLable != '负责人') {
                        data.push(date[a])
                      }
                    }
                    const obj = {
                      flow_id: '7TvJHPyovM',
                      flow_name: '客户录入单'
                    }
                    data.forEach(item => {
                      if (item.componentName == 'textfield' && item.defaultLable == '客户' && item.idx == 2) {
                        console.log(xiangqing.cust_name)
                        this.$set(item, 'value', xiangqing.cust_name)
                        this.$set(item, 'value_id', xiangqing.cust_id)
                      }
                      if (item.componentName == 'department' && item.defaultLable == '部门' && item.idx == 1) {
                        const dept = []
                        const name = xiangqing.dept_name.split(',')
                        const id = xiangqing.dept_id.split(',')
                        const length = xiangqing.dept_name.split(',').length
                        for (let i = 0; i < length; i++) {
                          dept.push({
                            dept_name: name[i],
                            dept_id: id[i]
                          })
                        }
                        this.$set(item, 'option', dept)
                        this.$set(item, 'dept_id', xiangqing.dept_name)
                        this.$set(item, 'dept_idid', id)
                      }
                      if (item.componentName == 'ddselectfield' && item.defaultLable == '地区' && item.idx == 16) {
                        this.$set(item, 'value', xiangqing.province)
                        this.$set(item, 'value_id', xiangqing.city)
                      }
                      if (item.componentName == 'ddselectfield' && item.defaultLable == '行业' && item.idx == 17) {
                        console.log(item)
                        item.defaultOptions.forEach((ite, ind) => {
                          if (ite.text == xiangqing.industry) {
                            this.$set(item, 'value', ind)
                            this.$set(item, 'teshu', false)
                          }
                        })
                        if (item.value == undefined) {
                          this.$set(item, 'value', '0')
                          this.$set(item, 'teshu', false)
                        }
                      }
                      if (item.componentName == 'ddselectfield' && item.defaultLable == '客户来源' && item.idx == 18) {
                        console.log(item)
                        item.defaultOptions.forEach((ite, ind) => {
                          if (ite.text == xiangqing.source) {
                            this.$set(item, 'value', ind)
                            this.$set(item, 'teshu', false)
                          }
                        })
                        if (item.value == undefined) {
                          this.$set(item, 'value', '0')
                          this.$set(item, 'teshu', false)
                        }
                      }
                      if (item.componentName == 'ddselectfield' && item.defaultLable == '客户等级' && item.idx == 19) {
                        console.log(item)
                        item.defaultOptions.forEach((ite, ind) => {
                          if (ite.text == xiangqing.level) {
                            this.$set(item, 'value', ind)
                            this.$set(item, 'teshu', false)
                          }
                        })
                        if (item.value == undefined) {
                          this.$set(item, 'value', '0')
                          this.$set(item, 'teshu', false)
                        }
                      }
                      if (item.componentName == 'ddmultiselectfield' && item.defaultLable == '客户标签' && item.idx == 20) {
                        console.log(item)
                        this.$set(item, 'teshu', false)
                        this.$set(item, 'value', this.xuan_zhong[0].tag_name)
                        this.$set(item, 'value_id', xiangqing.tag)
                      }
                      if (item.componentName == 'textfield' && item.defaultLable == '经营地址' && item.idx == 21) {
                        this.$set(item, 'value', xiangqing.biz_addr != undefined ? xiangqing.biz_addr : '')
                        this.$set(item, 'value_id', 0)
                      }
                      if (item.componentName == 'textfield' && item.defaultLable == '母公司名称' && item.idx == 22) {
                        this.$set(item, 'value', xiangqing.rel_p_com != undefined ? xiangqing.rel_p_com : '')
                        this.$set(item, 'value_id', 0)
                      }
                    })
                    console.log(data)
                    this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan', obj, '暂时')
                    this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_2', data, '暂时')
                    this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_3', '公海池_编辑', '暂时')
                    this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_4', xiangqing, '暂时')
                    this.$router.push('/usetable')
                  } else if (res.data.code == 500) {}
                })
                //     }else if(ras.data.code==10246){
                //         this.shifou=true
                //         this.danzi_name='客户录入单'
                //     }
                // })
              } else {
                this.$message({
                  message: '当前客户尚未审批完成',
                  type: 'warning'
                })
              }
            } else if (rds.data.code == 500) {}
          })
        } else {
          this.$message({
            message: '请选择单个客户进行编辑',
            type: 'warning'
          })
        }
      }
    },
    // 查询通知自己事件
    zhipai_tongzhi () {
      query_customer_allot_log_list_feed_back({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date.length != 0) {
            date.forEach(item => {
              this.tongzhi = true
              this.tanchuang_tongzhi = item
            })
            // date.forEach(it=>{
            //     console.log(it)
            //     // if(it.read_flag==0){
            //     //     this.tongzhi=true
            //     //     this.tanchuang_tongzhi=it
            //     //     break
            //     // }
            // })
            // for(let a=0;a<date.length;a++){
            //     if(date[a].read_flag==0){
            //         this.tongzhi=true
            //         this.tanchuang_tongzhi=date[a]
            //         break
            //     }
            // }
          } else {
            this.tongzhi = false
          }
        } else if (res.data.code == 500) {}
      })
    },
    // 表头颜色
    biaotou (row) { // 根据报警级别显示颜色
      return 'text-align:center;color:#1A2533;'
    },
    // color:#4C4A4D;font-size:0.12rem;
    liebiao (row) {
      return 'text-align:center;color:#1A2533;'
    },
    xuanzhong (val) {
      this.xuan_zhong = val
      console.log(val)
    },

    // 点击选择全部
    dianji_xuanze_quanbu () {
      this.xianshi_quanbu = !this.xianshi_quanbu
      if (this.xianshi_quanbu) {
        this.zhanshi_list.map((i, index) => { i.zhi = true })
        this.xianshi_xiang_list = []
        for (let i = 0; i < this.zhanshi_list.length; i++) {
          this.xianshi_xiang_list.push(this.zhanshi_list[i])
        }
      } else {
        this.zhanshi_list.map((i, index) => { i.zhi = false })
        this.xianshi_xiang_list = []
      }
    },
    xuanze_danxuan (i) {
      i.zhi = !i.zhi
      if (i.zhi) {
        this.xianshi_xiang_list.push(i)
      } else {
        for (let a = 0; a < this.xianshi_xiang_list.length; a++) {
          if (this.xianshi_xiang_list[a].con == i.con) {
            this.xianshi_xiang_list.splice(a, 1)
          }
        }
      }
    },
    dianji_shanchu_xianshi (i, index) {
      this.zhanshi_list.map((item, idx) => {
        if (item.con == i.con) {
          item.zhi = false
        }
      })
      this.xianshi_xiang_list.splice(index, 1)
    },
    dianji_lie_queding () {
      this.biao2_tit = this.xianshi_xiang_list
      this.xianshi_xiang_list = []
      this.xianshi_shezhi = false
    },
    sousuo () {
      const liexing = this.gonghai_sj_zhi.length != 0 ? this.gonghai_sj_zhi : null
      const date = this.gonghai_chi_time
      let start_time = null
      let end_time = null
      if (date != null && date != undefined && date.length != 0) {
        start_time = date[0].getFullYear() + '-' + this.$func.pa(date[0].getMonth() + 1) + '-' + this.$func.pa(date[0].getDate())
        end_time = date[1].getFullYear() + '-' + this.$func.pa(date[1].getMonth() + 1) + '-' + this.$func.pa(date[1].getDate())
      }
      const obj = {
        ent_id: this.$ent_id(),
        search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null,
        // time_type:liexing,
        // date_start:start_time,
        // date_end:end_time,
        user_id: this.$jichuxinxi().user_id,
        page_size: this.tiaoshu + '',
        page_index: '1'
      }
      this.dangqian_yeshu = 1
      this.sousuo_jiekou(obj)
    },
    rqi_xuanze () {
      this.gonghai_sj_zhi = this.gonghai_sj_zhi.length == 0 ? '1' : this.gonghai_sj_zhi
      const date = this.gonghai_chi_time
      let start_time = null
      let end_time = null
      if (date != null && date != undefined && date.length != 0) {
        start_time = date[0].getFullYear() + '-' + this.$func.pa(date[0].getMonth() + 1) + '-' + this.$func.pa(date[0].getDate())
        end_time = date[1].getFullYear() + '-' + this.$func.pa(date[1].getMonth() + 1) + '-' + this.$func.pa(date[1].getDate())
      }
      const obj = {
        ent_id: this.$ent_id(),
        search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null,
        // time_type:this.gonghai_sj_zhi.length!=0?this.gonghai_sj_zhi:null,
        // date_start:start_time,
        // date_end:end_time,
        user_id: this.$jichuxinxi().user_id,
        page_size: this.tiaoshu + '',
        page_index: this.dangqian_yeshu + ''
      }
      this.sousuo_jiekou(obj)
    },
    sousuo_jiekou (obj) {
      showLoading()
      query_customer_list_sea({ data: obj }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          // date.list.map(item=>{
          // if(Number(item.stay_long)>=Number(this.lv1)&&Number(item.stay_long)<=Number(this.lv2)){
          //     this.$set(item,'sj_time','0')
          //     this.$set(item,'time',Number(this.lv1)+'-'+Number(this.lv2)+'天')
          // }else if(Number(item.stay_long)>Number(this.yellow1)&&Number(item.stay_long)<=Number(this.yellow2)){
          //     this.$set(item,'sj_time','1')
          //     this.$set(item,'time',Number(this.yellow1)+'-'+Number(this.yellow2)+'天')
          // }else if(Number(item.stay_long)>Number(this.red)){
          //     this.$set(item,'sj_time','2')
          //     this.$set(item,'time',Number(this.red)+'天以上')
          // }
          // let chengjiao_jine=''
          // if(item.amt_trans!=undefined){
          //     let jine_list=item.amt_trans.split(',')
          //     let jine_zhan=[]
          //     jine_list.forEach(it=>{
          //         jine_zhan.push(this.$qianwei(Number(it).toFixed(2)))
          //     })
          //     chengjiao_jine=jine_zhan.join(',')
          // }
          // this.$set(item,'chengjiao_jine',chengjiao_jine)
          // })
          this.dangqian_zongshu = date.total
          this.tableData2 = date.list
          hideLoading()
        } else if (res.data.code == 500) {}
      })
    },
    shijian_xuanze () {
      const date = this.gonghai_chi_time
      let start_time = null
      let end_time = null
      if (date != null && date != undefined && date.length != 0) {
        start_time = date[0].getFullYear() + '-' + this.$func.pa(date[0].getMonth() + 1) + '-' + this.$func.pa(date[0].getDate())
        end_time = date[1].getFullYear() + '-' + this.$func.pa(date[1].getMonth() + 1) + '-' + this.$func.pa(date[1].getDate())
        const obj = {
          ent_id: this.$ent_id(),
          search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null,
          // time_type:this.gonghai_sj_zhi.length!=0?this.gonghai_sj_zhi:null,
          // date_start:start_time,
          // date_end:end_time,
          user_id: this.$jichuxinxi().user_id,
          page_size: this.tiaoshu + '',
          page_index: this.dangqian_yeshu + ''
        }
        this.sousuo_jiekou(obj)
      }
    },
    zhipai_danxuan (i) {
      i.value_zhipai = !i.value_zhipai
    },
    dianji_ren_xianshi (i, index) {
      this.yixuan_ren_list.splice(index, 1)
      this.renyuan_list.forEach((ite, idx) => {
        if (i.staff_id == ite.staff_id) {
          this.$set(ite, 'zhi', false)
          this.$set(ite, 'value_num', '-1')
        }
      })
    },
    // dianji_zhipai_queding(){
    //     if(this.yixuan_ren_list.length!=0){
    //         let list=[]
    //         let cust_ids=this.xuan_zhong.map(i=>i.cust_id).join(',')
    //         this.yixuan_ren_list.forEach(item=>{
    //             list.push({
    //                 staff_id:item.staff_id,
    //                 dept_id:item.dept_id
    //             })
    //         })
    //         allot_customer({data:{
    //             ent_id:this.$ent_id(),
    //             list:list,
    //             user_id:this.$jichuxinxi().user_id,
    //             cust_id:cust_ids
    //         }}).then(res=>{
    //             console.log(res)
    //             if(res.data.code==200){
    //                 this.$message({
    //                     message: '已通知对方',
    //                     type: 'success'
    //                 });
    //                 this.jichu()
    //                 this.zhipai_liebiao=false
    //             }else if(res.data.code==500){}
    //         })
    //     }else{
    //         this.$message({
    //             message: '请选择指派人员',
    //             type: 'warning'
    //         });
    //     }
    // },
    xuanze_zhipai_renyuan (i, ind) {
      i.value_num = ind
      i.value_zhipai = false
      i.zhi = true
      if (this.yixuan_ren_list.length == 0) {
        this.yixuan_ren_list.push({
          name: i.staff_name,
          staff_id: i.staff_id,
          dept_name: i.dept_list[i.value_num].dept_name,
          dept_id: i.dept_list[i.value_num].dept_id
        })
      } else {
        for (let a = 0; a < this.yixuan_ren_list.length; a++) {
          if (i.staff_id == this.yixuan_ren_list[a].staff_id) {
            this.yixuan_ren_list.splice(a, 1)
            break
          }
        }
        this.yixuan_ren_list.push({
          name: i.staff_name,
          staff_id: i.staff_id,
          dept_name: i.dept_list[i.value_num].dept_name,
          dept_id: i.dept_list[i.value_num].dept_id
        })
      }
    },
    dianji_zhidao_le () {
      // query_user_info({data:{
      //     ent_id:this.$ent_id(),
      //     user_id:this.$jichuxinxi().user_id
      // }}).then(ras=>{
      //     console.log(ras)
      //     if(ras.data.code==200){
      //         let date=JSON.parse(ras.data.body.data)
      update_customer_allot_log_read_flag({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          staff_id: this.tanchuang_tongzhi.staff_id,
          // cust_id:this.tanchuang_tongzhi.cust_id,
          dept_id: this.tanchuang_tongzhi.list[0].dept_id,
          allot_user_id: this.tanchuang_tongzhi.list[0].allot_user_id
        }
      }).then(res => {
        if (res.data.code == 200) {
          this.tongzhi = false
          this.jichu()
        } else if (res.data.code == 500) {}
      })
      //     }else if(ras.data.cdoe==500){}
      // })
    },
    // 表格行点
    hangdian (val) {
      console.log(val, 123123123)
      query_customer_detail({
        data: {
          ent_id: this.$ent_id(),
          cust_id: val.cust_id,
          file_no: val.file_no,
          dept_id: val.dept_id
        }
      }).then(rds => {
        console.log(rds, 213232123)
        if (rds.data.code == 200) {
          const xiangqing = JSON.parse(rds.data.body.data)
          console.log(xiangqing, 'asdsad')
          this.xiangxi_xinxi = []
          this.xiangxi_xinxi.push(xiangqing)
          console.log(this.xiangxi_xinxi, 8934722)
          this.xiangxi_xinxi.forEach(item => {
            this.$set(item, 'zidingyi', item.extra != undefined ? JSON.parse(item.extra) : [])
            this.$set(item, 'dept_name', val.dept_name != undefined ? val.dept_name : '')
          })
          // 附件下载接口
          customer_annex_download({
            data: {
              ent_id: this.$ent_id(),
              cust_id: val.cust_id,
              // dept_id:val.dept_id,
              file_no: xiangqing.file_no,
              staff_id: val.staff_id != undefined ? val.staff_id : null
            }
          }).then(fujian => {
            console.log(fujian)
            if (fujian.data.code == 200) {
              const date = JSON.parse(fujian.data.body.data)
              console.log(date)
              date.forEach(item => {
                if (item.con_annex != undefined) {
                  item.con_annex.forEach(ite => {
                    this.$set(ite, 'zhi', false)
                  })
                }
                if (item.inv_annex != undefined) {
                  item.inv_annex.forEach(ite => {
                    this.$set(ite, 'zhi', false)
                  })
                }
                if (item.rec_annex != undefined) {
                  item.rec_annex.forEach(ite => {
                    this.$set(ite, 'zhi', false)
                  })
                }
                if (item.deliver_annex != undefined) {
                  item.deliver_annex.forEach(ite => {
                    this.$set(ite, 'zhi', false)
                  })
                }
              })
              for (let i = 0; i < date.length; i++) {
                if (date[i].file_no == undefined) {
                  date.splice(i, 1)
                  i = i - 1
                }
              }
              setTimeout(() => {
                this.fujian_list = date
              }, 0)
            } else if (fujian.data.code == 500) {}
          })
        } else if (rds.data.code == 500) {}
      })
      query_customer_mng_log_list({
        data: {
          ent_id: this.$ent_id(),
          cust_id: val.cust_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.zhanshi_lishi = date != undefined && date != null && date.length != 0 ? date : []

          // let list=[]
          // date.forEach(ite=>{
          //     // if(ite.type!=4&&ite.type!=5){
          //         list.push(ite)
          //     // }
          // })
          // query_cust_allot_log_list({data:{
          //     ent_id:this.$ent_id(),
          //     // allot_user_id:this.$jichuxinxi().user_id,
          //     cust_id:val.cust_id
          // }}).then(ras=>{
          //     console.log(ras)
          //     if(ras.data.code==200){
          //         let data=JSON.parse(ras.data.body.data)
          //         console.log(data)
          //         data.forEach(item=>{
          //             if(item.accept_status==0){
          //                 this.$set(item,'operate_time',item.allot_time)
          //                 this.$set(item,'type',7)
          //                 this.$set(item,'operate_user_name',item.allot_user_name)
          //                 list.push(item)
          //             }else if(item.accept_status==1){
          //                 this.$set(item,'operate_time',item.allot_time)
          //                 this.$set(item,'type',4)
          //                 this.$set(item,'operate_user_name',item.allot_user_name)
          //                 list.push(item)
          //             }else if(item.accept_status==2){
          //                 this.$set(item,'operate_time',item.allot_time)
          //                 this.$set(item,'type',5)
          //                 this.$set(item,'operate_user_name',item.allot_user_name)
          //                 list.push(item)
          //             }
          //         })
          //         list=this.$func.ReverseRankingDate(list,'operate_time')
          //         console.log(list)
          //         this.zhanshi_lishi=list
          //     }else if(res.data.code==500){}
          // })
        } else if (res.data.code == 500) {}
      })
      query_customer_else_info({
        data: {
          ent_id: this.$ent_id(),
          cust_id: val.cust_id,
          type: '2',
          dept_id: val.dept_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          console.log(this.lv1)
          console.log(this.lv2)
          if (Number(date.stay_long) >= Number(this.lv1) && Number(date.stay_long) <= Number(this.lv2)) {
            this.right_zhanshi.color = '0'
            this.right_zhanshi.tingliu_shichang = Number(this.lv1) + '-' + Number(this.lv2) + '天'
          } else if (Number(date.stay_long) > Number(this.yellow1) && Number(date.stay_long) <= Number(this.yellow2)) {
            this.right_zhanshi.color = '1'
            this.right_zhanshi.tingliu_shichang = Number(this.yellow1) + '-' + Number(this.yellow2) + '天'
          } else if (Number(date.stay_long) > Number(this.red)) {
            this.right_zhanshi.color = '2'
            this.right_zhanshi.tingliu_shichang = Number(this.red) + '天以上'
          }
          this.right_zhanshi.chengjiao_sj = date.date_trans.length != 0 ? date.date_trans : '—'
          this.right_zhanshi.chengjiao_jine = Number(date.amt_trans).toFixed(2)
          this.right_zhanshi.tuihui_cishu = date.back_num
        } else if (res.data.code == 500) {}
      })
      this.youce_xiangqing = true
    },
    fujian_xiazai (i) {
      this.$func.fujian_xiazai2(i)
    },
    shaixuan_sousuo () {
      if (this.sousuo_text2 == null || this.sousuo_text2.length == 0) {
        this.sousuo_text2 = null
        // this.chaosong_bumen_renyuan_jiekou()
        query_ent_dept_list_aim({
          data: {
            ent_id: this.$ent_id(),
            user_id: (this.zhuguan_liyuan || this.ziguan_liyuan) ? null : this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10168) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            date.forEach(item => {
              this.$set(item, 'dept_name', item.label)
            })
            this.chaosong_qiye_bumen_list = date
          } else if (res.data.code == 10169) {}
        })
        this.chaosong_qiye_renyuan_list = []
      } else {
        get_staff_list_like({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            staff_name: this.sousuo_text2,
            active: '1'
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10027) {
            const date = JSON.parse(res.data.body.data)
            date.map(item => { this.$set(item, 'zhi', false); this.$set(item, 'name', item.staff_name) })
            this.chaosong_qiye_renyuan_list = date
            this.panduan_youce_zuoce_have()
          } else if (res.data.code == 10028) {
          }
        })
      }
    },
    mianbao_chaosong_dian (i, index) {
      if (this.mianbao_chaosong_list.length - 1 != index && index != 0) {
        this.mianbao_chaosong_list.splice(index + 1, this.mianbao_chaosong_list.length)
        this.chaosong_bumen_renyuan_jiekou()
      } else if (index == 0) {
        this.mianbao_chaosong_list.splice(index + 1, this.mianbao_chaosong_list.length)
        query_ent_dept_list_aim({
          data: {
            ent_id: this.$ent_id(),
            user_id: (this.zhuguan_liyuan || this.ziguan_liyuan) ? null : this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10168) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            date.forEach(item => {
              this.$set(item, 'dept_name', item.label)
            })
            this.chaosong_qiye_bumen_list = date
          } else if (res.data.code == 10169) {}
        })
        this.chaosong_qiye_renyuan_list = []
      }
    },
    // dianji_chaosong_quanxuan(){
    //     this.renyuan_chaosong_quanxuan=!this.renyuan_chaosong_quanxuan
    //     if(this.renyuan_chaosong_quanxuan){
    //         // for(let i=0;i<this.chaosong_qiye_bumen_list.length;i++){
    //             // this.chaosong_qiye_bumen_list.map(item=>{
    //             //     item.zhi=true
    //             //     this.$set(item,'name',item.dept_name)
    //             //     this.$set(item,'ids',item.dept_id)
    //             //     this.$set(item,'type',1)
    //             //     this.chaosong_renyuan_list_xuan.push(item)
    //             // })
    //         // }
    //         // for(let i=0;i<this.chaosong_qiye_renyuan_list.length;i++){
    //             this.chaosong_qiye_renyuan_list.map(item=>{
    //                 item.zhi=true
    //                 this.$set(item,'name',item.staff_name)
    //                 this.$set(item,'ids',item.staff_id)
    //                 this.$set(item,'type',2)
    //                 this.chaosong_renyuan_list_xuan.push(item)
    //             })
    //         // }
    //     }else{
    //         this.chaosong_renyuan_list_xuan=[]
    //         // this.chaosong_qiye_bumen_list.map(item=>{
    //         //     item.zhi=false
    //         // })
    //         this.chaosong_qiye_renyuan_list.map(item=>{
    //             item.zhi=false
    //         })
    //     }
    // },
    dianji_chaosong_bumen (i) {
      this.mianbao_chaosong_list.push({ name: i.dept_name, dept_id: i.dept_id })
      this.chaosong_bumen_renyuan_jiekou()
    },
    // 抄送人部门人员列表
    chaosong_bumen_renyuan_jiekou () {
      query_ent_dept_list({
        data: {
          ent_id: this.$ent_id(),
          active: '1',
          parent_id: this.mianbao_chaosong_list[this.mianbao_chaosong_list.length - 1].dept_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          // date.map(item=>{this.$set(item,'zhi',false)})
          console.log(date)
          this.chaosong_qiye_bumen_list = date
          query_dept_staff_list({
            data: {
              ent_id: this.$ent_id(),
              dept_id: this.mianbao_chaosong_list[this.mianbao_chaosong_list.length - 1].dept_id,
              active: '1'
            }
          }).then(ras => {
            if (ras.data.code == 10149) {
              const listdata = JSON.parse(ras.data.body.data)
              listdata.map(item => { this.$set(item, 'zhi', false) })
              console.log(99999, listdata)
              if (this.zhuguan) {
                this.chaosong_qiye_renyuan_list = listdata
              } else {
                const arr = []
                listdata.forEach(it => {
                  if (it.staff_id == this.$jichuxinxi().user_id) {
                    arr.push(it)
                  }
                })
                this.chaosong_qiye_renyuan_list = arr
              }
              this.panduan_youce_zuoce_have()
              this.panduan_chaosong_quanxuan()
            } else if (ras.data.code == 10150) {
            }
          })
        } else if (res.data.code == 10169) {
        }
      })
    },
    // chaosong_bumen(i){
    //     i.zhi=!i.zhi
    //     if(i.zhi){
    //         this.$set(i,'name',i.dept_name)
    //         this.$set(i,'ids',i.dept_id)
    //         this.$set(i,'type',1)
    //         this.chaosong_renyuan_list_xuan.push(i)
    //     }else{
    //         for(let a=0;a<this.chaosong_renyuan_list_xuan.length;a++){
    //             if(this.chaosong_renyuan_list_xuan[a].ids==i.dept_id){
    //                 this.chaosong_renyuan_list_xuan.splice(a,1)
    //             }
    //         }
    //     }
    //     this.panduan_chaosong_quanxuan()
    // },
    chaosong_renyuan_sure () {
      if (this.chaosong_renyuan_list_xuan.length != 0) {
        const list = []
        const cust_ids = this.xuan_zhong.map(i => i.cust_id).join(',')
        this.chaosong_renyuan_list_xuan.forEach(item => {
          list.push({
            staff_id: item.staff_id,
            dept_id: item.bumen_id
          })
        })
        console.log(this.chaosong_renyuan_list_xuan)
        console.log(cust_ids)
        console.log(list)
        query_user_info({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id
          }
        }).then(ras => {
          console.log(ras)
          if (ras.data.code == 200) {
            const date = ras.data.body
            allot_customer({
              data: {
                ent_id: this.$ent_id(),
                list: list,
                user_id: this.$jichuxinxi().user_id,
                operate_dept_id: date.dept_ids != undefined && date.dept_ids != null && date.dept_ids.length != 0 ? date.dept_ids : null,
                cust_id: cust_ids
              }
            }).then(res => {
              console.log(res)
              if (res.data.code == 200) {
                this.$message({
                  message: '已通知对方',
                  type: 'success'
                })
                this.jichu()
                this.zhipai_liebiao = false
              } else if (res.data.code == 500) {} else if (res.data.code == 10250) {
                this.$message({
                  message: '公海池客户目前不够分配',
                  type: 'warning'
                })
              } else if (res.data.code == 10251) {
                this.$message({
                  message: '公海池客户已超额分配',
                  type: 'warning'
                })
              } else if (res.data.code == 10300) {
                this.$message({
                  message: '企业还没有设置分配规则',
                  type: 'warning'
                })
              } else if (res.data.code == 600) {
                this.$message({
                  message: '客户只能在一个部门指派一次',
                  type: 'warning'
                })
              }
            })
          } else if (ras.data.cdoe == 500) {}
        })
      } else {
        this.$message({
          message: '请选择指派人员',
          type: 'warning'
        })
      }
      // if(this.dangqian_xuanze_name=='人员'){
      //     customer_manager_main_interface({data:{
      //         ent_id:this.$ent_id(),
      //         query_type:'3',
      //         staff_id:this.chaosong_renyuan_list_xuan.length!=0?this.chaosong_renyuan_list_xuan.map(ite=>ite.staff_id).join(','):null,
      //         user_id:this.$jichuxinxi().user_id
      //     }}).then(res=>{
      //         if(res.data.code==200){
      //             this.shaixuan_name=this.chaosong_renyuan_list_xuan.map(ite=>ite.staff_name).join(',')
      //             let date=res.data.body
      //             this.tu1[0].value=date.total_sea
      //             this.tu1[1].value=date.total_alloted
      //             this.tu2[0].value=date.alloted_sys
      //             this.tu2[1].value=date.alloted_add
      //             this.tu3[0].value=date.sea_unalloted
      //             this.tu3[1].value=date.sea_back
      //             this.tu3[2].value=date.sea_cold
      //             if(this.shifou_keshi||this.zhuguan||this.zhu_guanli_yuan){
      //                 this.canver();
      //                 this.canver_3()
      //             }
      //             this.canver_2()
      //             this.shaixuan_ren=false
      //         }else if(res.data.code==500){}
      //     })
      // }
    },
    // 判断抄送是否全选
    panduan_chaosong_quanxuan () {
      const list = []
      // for(let i=0;i<this.chaosong_qiye_bumen_list.length;i++){
      //     if(!this.chaosong_qiye_bumen_list[i].zhi){
      //         list.push('111')
      //     }
      // }
      for (let i = 0; i < this.chaosong_qiye_renyuan_list.length; i++) {
        if (!this.chaosong_qiye_renyuan_list[i].zhi) {
          list.push('111')
        }
      }
      if (list.length == 0) {
        this.renyuan_chaosong_quanxuan = true
      } else {
        this.renyuan_chaosong_quanxuan = false
      }
    },
    // 判断右侧有的左侧是否有
    panduan_youce_zuoce_have () {
      for (let i = 0; i < this.chaosong_renyuan_list_xuan.length; i++) {
        // for(let a=0;a<this.chaosong_qiye_bumen_list.length;a++){
        //     if(this.chaosong_renyuan_list_xuan[i].ids==this.chaosong_qiye_bumen_list[a].dept_id){
        //         this.chaosong_qiye_bumen_list[a].zhi=true
        //     }
        // }
        for (let a = 0; a < this.chaosong_qiye_renyuan_list.length; a++) {
          if (this.chaosong_renyuan_list_xuan[i].ids == this.chaosong_qiye_renyuan_list[a].staff_id && this.mianbao_chaosong_list[this.mianbao_chaosong_list.length - 1].dept_id == this.chaosong_renyuan_list_xuan[i].bumen_id) {
            this.chaosong_qiye_renyuan_list[a].zhi = true
          }
        }
      }
    },
    dianji_chaosong_xuanren (i) {
      console.log(i)
      // i.zhi=!i.zhis
      let ce = []
      if (!i.zhi) {
        for (let a = 0; a < this.chaosong_renyuan_list_xuan.length; a++) {
          if (this.mianbao_chaosong_list[this.mianbao_chaosong_list.length - 1].dept_id == this.chaosong_renyuan_list_xuan[a].bumen_id) {
            ce.push('1111')
          }
        }
        if (ce.length != 0) {
          this.$message({
            message: '一个部门只能指派一个人员',
            type: 'warning'
          })
          i.zhi = false
        } else {
          i.zhi = true
          this.$set(i, 'name', i.staff_name)
          this.$set(i, 'ids', i.staff_id)
          this.$set(i, 'type', 2)
          this.$set(i, 'bumen_id', this.mianbao_chaosong_list[this.mianbao_chaosong_list.length - 1].dept_id)
          this.$set(i, 'dept_name', this.mianbao_chaosong_list[this.mianbao_chaosong_list.length - 1].name)
          // if(this.chaosong_renyuan_list_xuan.length!=0){
          //     for(let a=0;a<this.chaosong_renyuan_list_xuan.length;a++){
          //         if(this.chaosong_renyuan_list_xuan[a].ids==i.staff_id){
          //             this.chaosong_renyuan_list_xuan[a].dept_name=i.dept_name
          //             this.chaosong_renyuan_list_xuan[a].bumen_id=i.bumen_id
          //         }else{
          //             this.chaosong_renyuan_list_xuan.push(i)
          //         }
          //     }
          // }else{
          this.chaosong_renyuan_list_xuan.push(i)
          // }
        }
      } else {
        i.zhi = false
        this.$set(i, 'bumen_id', this.mianbao_chaosong_list[this.mianbao_chaosong_list.length - 1].dept_id)
        this.$set(i, 'ids', i.staff_id)
        for (let a = 0; a < this.chaosong_renyuan_list_xuan.length; a++) {
          if (this.chaosong_renyuan_list_xuan[a].ids == i.staff_id && i.bumen_id == this.chaosong_renyuan_list_xuan[a].bumen_id) {
            this.chaosong_renyuan_list_xuan.splice(a, 1)
          }
        }
        ce = []
      }
      // this.panduan_chaosong_quanxuan()
    },
    dianji_shanchu_kehu () {
      if (this.xuan_zhong.length != 0) {
        console.log(this.xuan_zhong)
        const list = []
        // split
        this.xuan_zhong.forEach(item => {
          if (item.dept_id != undefined) {
            if (item.dept_id.split(',').length > 1) {
              item.dept_id.split(',').forEach(ite => {
                list.push({
                  cust_id: item.cust_id,
                  dept_id: ite
                })
              })
            } else {
              list.push({
                cust_id: item.cust_id,
                dept_id: item.dept_id
              })
            }
          } else {
            list.push({
              cust_id: item.cust_id
            })
          }
        })
        console.log(list)

        delete_customer_sea({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            list: list
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.jichu()
            this.$message({
              message: '删除成功',
              type: 'success'
            })
          } else if (res.data.code == 500) {} else if (res.data.code == 11600) {
            this.$message({
              message: '已存在表单信息，不能删除客户',
              type: 'warning'
            })
          }
        })
      } else {
        this.$message({
          message: '请选择客户进行删除',
          type: 'warning'
        })
      }
    },
    fujian_xiazai (i) {
      this.$func.fujian_xiazai2(i)
    },
    dianji_guidang_hao (index) {
      this.zhanshi_guidang_hao = index
      const item = this.fujian_list[index]
      if (item.con_annex != undefined) {
        item.con_annex.forEach(ite => {
          this.$set(ite, 'zhi', false)
        })
      }
      if (item.inv_annex != undefined) {
        item.inv_annex.forEach(ite => {
          this.$set(ite, 'zhi', false)
        })
      }
      if (item.rec_annex != undefined) {
        item.rec_annex.forEach(ite => {
          this.$set(ite, 'zhi', false)
        })
      }
      if (item.deliver_annex != undefined) {
        item.deliver_annex.forEach(ite => {
          this.$set(ite, 'zhi', false)
        })
      }
    },
    dianji_quanxuan_fujian () {
      this.fujian_quanxuan = !this.fujian_quanxuan
      if (this.fujian_quanxuan) {
        const item = this.fujian_list[this.zhanshi_guidang_hao]
        if (item != undefined) {
          if (item.con_annex != undefined) {
            item.con_annex.forEach(ite => {
              this.$set(ite, 'zhi', true)
            })
          }
          if (item.inv_annex != undefined) {
            item.inv_annex.forEach(ite => {
              this.$set(ite, 'zhi', true)
            })
          }
          if (item.rec_annex != undefined) {
            item.rec_annex.forEach(ite => {
              this.$set(ite, 'zhi', true)
            })
          }
          if (item.deliver_annex != undefined) {
            item.deliver_annex.forEach(ite => {
              this.$set(ite, 'zhi', true)
            })
          }
        }
      } else {
        const item = this.fujian_list[this.zhanshi_guidang_hao]
        if (item != undefined) {
          if (item.con_annex != undefined) {
            item.con_annex.forEach(ite => {
              this.$set(ite, 'zhi', false)
            })
          }
          if (item.inv_annex != undefined) {
            item.inv_annex.forEach(ite => {
              this.$set(ite, 'zhi', false)
            })
          }
          if (item.rec_annex != undefined) {
            item.rec_annex.forEach(ite => {
              this.$set(ite, 'zhi', false)
            })
          }
          if (item.deliver_annex != undefined) {
            item.deliver_annex.forEach(ite => {
              this.$set(ite, 'zhi', false)
            })
          }
        }
      }
    },
    dianji_piliang_xiazi () {
      const list = []
      const item = this.fujian_list[this.zhanshi_guidang_hao]
      if (item != undefined) {
        if (item.con_annex != undefined) {
          item.con_annex.forEach(ite => {
            if (ite.zhi) {
              list.push(ite)
            }
          })
        }
        if (item.inv_annex != undefined) {
          item.inv_annex.forEach(ite => {
            if (ite.zhi) {
              list.push(ite)
            }
          })
        }
        if (item.rec_annex != undefined) {
          item.rec_annex.forEach(ite => {
            if (ite.zhi) {
              list.push(ite)
            }
          })
        }
        if (item.deliver_annex != undefined) {
          item.deliver_annex.forEach(ite => {
            if (ite.zhi) {
              list.push(ite)
            }
          })
        }
        if (list.length != 0) {
          list.forEach(it => {
            this.$func.fujian_xiazai2(it)
          })
        } else {
          this.$message({
            message: '请选择附件进行下载',
            type: 'warning'
          })
        }
      }
    },
    panduan_fujian_quanxuan () {
      const list = []
      const item = this.fujian_list[this.zhanshi_guidang_hao]
      if (item != undefined) {
        console.log(item)
        if (item.con_annex != undefined) {
          const quanbu = []
          item.con_annex.forEach(ite => {
            console.log(ite)
            if (ite.zhi) {
              quanbu.push(ite)
            }
          })
          if (quanbu.length == item.con_annex.length) {
            list.push('1111')
          }
          console.log(quanbu)
        } else {
          list.push('1111')
        }
        if (item.inv_annex != undefined) {
          const quanbu = []
          item.inv_annex.forEach(ite => {
            if (ite.zhi) {
              quanbu.push(ite)
            }
          })
          if (quanbu.length == item.inv_annex.length) {
            list.push('1111')
          }
        } else {
          list.push('1111')
        }
        if (item.rec_annex != undefined) {
          const quanbu = []
          item.rec_annex.forEach(ite => {
            if (ite.zhi) {
              quanbu.push(ite)
            }
          })
          if (quanbu.length == item.rec_annex.length) {
            list.push('1111')
          }
        } else {
          list.push('1111')
        }
        if (item.deliver_annex != undefined) {
          const quanbu = []
          item.deliver_annex.forEach(ite => {
            if (ite.zhi) {
              quanbu.push(ite)
            }
          })
          if (quanbu.length == item.deliver_annex.length) {
            list.push('1111')
          }
        } else {
          list.push('1111')
        }
        setTimeout(() => {
          console.log(list)
          if (list.length == 4 && (item.deliver_annex != undefined || item.con_annex != undefined || item.inv_annex != undefined || item.rec_annex != undefined)) {
            this.fujian_quanxuan = true
          } else {
            this.fujian_quanxuan = false
          }
        }, 0)
      }
    },
    dianji_xiazai_fujian (i) {
      this.$func.fujian_xiazai(i)
    },
    // 点击表单详情
    biaodan_xiangqing (i) {
      this.$refs.biaodan_xiangqing_zhanshi[0].jichu(i)
    },
    // 点击眼睛
    dakai_danju (i) {
      this.$refs.eye.dianji_dakai(i[0])
    },
    tupian_dianji (img) {
      this.$refs.bigphote.jichu(img)
    },
    // 金额转化
    jine_zhuanhua (num) {
      const a = Number(num)
      if (a >= 100000000) {
        return this.$qianwei((a / 100000000).toFixed(2))
      } else if (a >= 10000 && a < 100000000) {
        return this.$qianwei((a / 10000).toFixed(2))
      } else if (a < 10000) {
        return this.$qianwei(a.toFixed(2))
      }
    },
    // 金额文字转化
    wenzi_jine_zhuanhua (num) {
      const a = Number(num)
      if (a >= 100000000) {
        return '亿'
      } else if (a >= 10000 && a < 100000000) {
        return '万元'
      } else if (a < 10000) {
        return '元'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import '../../style/salesman.scss'
</style>
